
.login > .ant-input {
    font-size: 1.125rem !important;
}

.login > .ant-input-password > .ant-input {
    font-size: 1.125rem !important;
}

.login__button:hover {
    background-color: rgba(8, 14, 51, 0.7);
}

.login__button > button:disabled {
    opacity: 50%;
}