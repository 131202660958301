.ant-breadcrumb-separator {
    color: #cbd5e0 !important;
}

.categoryHeader__banner {            
    background-size: cover !important;
    background-position: center, center !important;
    background-repeat: no-repeat !important;
}

.category__title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}