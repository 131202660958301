.enclosure__image {    
    display: block;
    z-index: 5;
}

.feedBox__link:hover {
  text-decoration: underline !important;
}

.enclosure__overlay { 
  position: absolute;   
  top: 0;
  left: 0;
  right: 0;
  height: 80%;  
  opacity: 1;  
  z-index: 1;
  background-color: rgba(0,0,0,0.3);
}

.enclosure__container {
    position: relative;
}

.homeBox__image {
  object-fit: contain; 
  padding: 8px;
}

.homeBox__left {
  flex: 0.8;
}

.homeBox__description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.feedBox__categoryLabel {  
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.homeBox__hoverImage {    
  max-width: 200px;
  overflow: hidden;
}

.homeBox__hoverImage img {    
  transition: all .2s ease-in-out;
}

.homeBox__hoverImage:hover img {
  transform: scale(1.1);
}

.homeBox__description > p { 
  width: 100%;
}

.homeBox__description > p > p { 
  width: 100%;
}

.truncate {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}