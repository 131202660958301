.editorjsRenderer a {
    text-decoration: underline;
}

@media only screen and (max-width: 600px) {
    .editorjs__title {
        max-width: 300px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }   
}
  