@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

mark {
  padding-bottom: 10px !important;
  background: linear-gradient( 0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 15%, #080E33 15%, #080E33 20%, rgba(255,255,255,0) 20%, rgba(255,255,255,0) 100% );
}

* {
  margin: 0;
}

.terms a {  
  text-decoration: underline;
}

.ant-switch {
  background-color: #d32f2f !important;
}

.ant-switch-checked {
  background-color: #2fd368 !important;
}

video[poster]{
  height: 250px;
  width: 250px;
  object-fit: fill;
}

.primary-btn {
  background-color: #080E33;
}

.primary-border {
  border-color: #080E33;
}

.primary-btn:hover {
  background-color: rgb(5, 13, 61);
}

.primary-bg {
  background-color: rgb(5, 13, 61);
}

.primary-color {
  color: #080E33;
}

.primary-color:hover {
  color: rgba(8, 14, 51, 0.7);
}

.topFeedBox__title {
    max-width: 250px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (max-width: 600px) {
  .topFeedBox__title {
    max-width: 300px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  } 
}

.cut__me {
  max-width: 150px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.categorySlide {
  max-height: 350px; 
  overflow-y: auto;
  overflow-x: hidden;  
}

.categorySlide::-webkit-scrollbar {  
  width: 5px;
  height: 5px; 
}

.categorySlide::-webkit-scrollbar-thumb {
  background: #c5c5c5;
}

